<template>
  <div class="dress">
    <v-container v-if="sale">
      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Cliente</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          NOMBRE: {{ sale.client.first_name + " " + sale.client.last_name }}
        </v-col>
        <v-col cols="4">
          INDENTIFICACIÓN: {{ sale.client.colombian_id }}
        </v-col>
        <v-col cols="4"> DIRECCIÓN: {{ sale.client.address }} </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> TELÉFONO: {{ sale.client.phone }} </v-col>
        <v-col cols="4"> CORREO: {{ sale.client.email }} </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Venta</v-toolbar-title
            >
            <v-chip class="ma-2" color="secondary" text-color="white" small>
              {{ sale.reference }}
            </v-chip>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          VALOR DE VENTA: {{ sale.sale_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          VALOR INICIAL: {{ sale.initial_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          ASESOR: {{ sale.employee ? sale.employee.full_name : "N/A" }}
        </v-col>
        <v-col cols="2">
          <v-btn
            elevation="2"
            :href="sale.invoice_url"
            rounded
            dark
            color="blue"
            target="_blank"
          >
            Factura
            <v-icon right dark> mdi-cloud-print </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2" v-if="sale.contract_url">
          <v-btn
            elevation="2"
            :href="sale.contract_url"
            rounded
            dark
            color="blue"
            target="_blank"
            >Contrato
            <v-icon right dark> mdi-file-document-multiple-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Vestidos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row v-for="(dress, index) in sale.dresses" :key="index">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-avatar color="secondary" size="25" class="white--text mr-3">{{
                index + 1
              }}</v-avatar>
              {{ dress.code }} - {{ dress.color }} -
              {{ dress.pivot.sale_value | formatNumber }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Pagos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersPayment"
            :items="sale.payments"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.value`]="{ item }">
              {{ item.value | formatNumber }}
            </template>
            <template v-slot:[`item.payment_method`]="{ item }">
              {{ getPaymentMethodsLan(item.payment_method) }}
            </template>
            <template v-slot:[`item.payment_type`]="item">
              {{ getPaymentTypeLan(item.item.payment_type) }}
            </template>
            <template v-slot:[`item.user`]="item">
              {{ item.item.user.name }}
            </template>
            <template v-slot:[`item.actions`]="item">
              <v-tooltip bottom v-if="item.item.payment_type !== 'INITIAL'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text :href="item.item.invoice_url" target="_bank">
                    <v-icon
                      color="blue"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="px-1"
                    >
                      mdi-cloud-print
                    </v-icon>
                  </v-btn>
                </template>
                <span>FACTURA</span>
              </v-tooltip>
              <span v-if="item.item.payment_type == 'INITIAL'">N/A</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Documentos
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              small
              fab
              dark
              color="indigo"
              @click="addDocuments"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersDocuments"
            :items="sale.documents_urls"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.created_at`]="item">
              {{ item.item.created_at.substr(0, 10) }}
            </template>
            <template v-slot:[`item.name`]="item">
              {{ item.item.name }}
            </template>
            <template v-slot:[`item.actions`]="item">
              <span v-if="role !== 'SUPERADMIN'"> N/A </span>
              <v-icon
                small
                @click="deleteDocument(item.item)"
                v-if="role == 'SUPERADMIN'"
                color="red"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog loader -->
    <v-dialog
      v-model="this.$store.state.sale.create.isLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add Docuements -->
    <v-dialog v-model="dialogAddDocuments" width="500" persistent>
      <v-card>
        <v-card-title>Agregar Documentos</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formAddDocuments" v-model="formAddDocuments">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-file-input
                    counter
                    multiple
                    truncate-length="15"
                    v-model="modelDocuments.files"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelAddDocument">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveDocuments">
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog delete document -->
    <v-dialog v-model="dialogDeleteDocuments" persistent max-width="550px">
      <v-card>
        <v-card-title class="headline"
          >Seguro que desea eliminar este documento?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDocument"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="confirmDeleteDocument"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_ALERT } from "@/store/alert";
import { SET_SALE } from "@/store/sale";
import { mapGetters } from "vuex";
export default {
  name: "Show",
  props: ["id"],
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    sale: null,
    currentDocument: null,
    formAddDocuments: true,
    dialogAddDocuments: false,
    dialogDeleteDocuments: false,
    modelDocuments: {
      id: null,
      files: "",
      type: "sale",
    },
    headersDocuments: [
      { text: "Fecha", value: "created_at", sortable: false },
      { text: "Documento", value: "name", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    headersPayment: [
      { text: "Valor", value: "value", sortable: false },
      { text: "Método de pago", value: "payment_method", sortable: false },
      { text: "Tipo de pago", value: "payment_type", sortable: false },
      { text: "Usuario", value: "user", sortable: false },
      { text: "Actiones", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.$store.dispatch("getSale", this.id);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_SALE) {
        this.sale = state.sale.sale;
      }
    });
  },
  computed: {
    ...mapGetters([
      "getPaymentMethodsLan",
      "getPaymentTypeLan",
      "getStatusColor",
      "getStatusLan",
    ]),
    role() {
      return this.$store.state.user.user.role;
    },
  },
  methods: {
    addDocuments() {
      this.dialogAddDocuments = true;
    },
    cancelAddDocument() {
      this.modelDocument = {
        documents: "",
        type: "sale",
      };
      this.$refs.formAddDocuments.resetValidation();
      this.dialogAddDocuments = false;
    },
    saveDocuments() {
      if (!this.$refs.formAddDocuments.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }
      this.modelDocuments.id = this.id;
      console.log(this.modelDocuments.files);
      this.$store.dispatch("addDocuments", this.setFormDataDocuments());
      this.cancelAddDocument();
    },
    setFormDataDocuments() {
      const formData = new FormData();

      Object.entries(this.modelDocuments).forEach(([key, value]) => {
        //vali if value is an array
        if (Array.isArray(value)) {
          value.forEach((element, index) => {
            formData.append(key + "[" + index + "]", element);
          });
        }
        if (value !== "" && value !== null) {
          formData.append(key, value);
        }
      });

      return formData;
    },
    closeDeleteDocument() {
      this.dialogDeleteDocuments = false;
      this.currentDocument = null;
    },
    deleteDocument(document) {
      this.currentDocument = document;
      this.dialogDeleteDocuments = true;
    },
    confirmDeleteDocument() {
      this.$store.dispatch("deleteDocuments", {
        id: this.id,
        type: "sale",
        key: this.currentDocument.key,
      });
      this.closeDeleteDocument();
    },
  },
};
</script>
